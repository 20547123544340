<template>
  <div class="detail-index-bg">
    <div class="detail-index">
      <div class="detail-index-crumbs">
      <span @click="goBack()" style="color:black;cursor:pointer;margin-right:0.1rem"
          >{{name}}</span>/
        
        <span class="detail-index-crumbs-this" style="margin-left:0.1rem">{{ info.name }}</span>
      </div>
      <div class="detail-index-top">
        <div class="detail-index-top-content-headPic">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/border-top.png"
            alt=""
          />
        </div>
        <div class="detail-index-top-content-footerPic">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
            alt=""
          />
        </div>
        <div class="detail-index-top-left">
          <img
            style="width: 100%; height: 100%"
            :src="info.detail_pic"
            alt=""
            v-if="showChapterId == null"
          />
          <img class="yinpinImg" src="https://image.bookgo.com.cn/image/bgmp3.png" style="width: 5.5rem;height: 3.6rem;position:absolute;z-index:1;top:0px" v-if="showChapterId != null&&currentType==0"/>
          <img class="yinpinImg2" :class="playStatus?'yinpinImg2x':''" src="https://image.bookgo.com.cn/image/yinpindx.png" v-if="showChapterId != null&&currentType==0"/>
          <img class="yinpinImg3" src="https://image.bookgo.com.cn/image/cipan.png" v-if="showChapterId != null&&currentType==0"/> -->
          <video-player
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            v-if="showChapterId > 0"
            style="width: 100%; height: 100%"
            :playsinline="true"
            :options="playerOptions"
            @pause="onPlayerPause($event)"
            @play="onPlayerPlay($event)"
          ></video-player>
        </div>
        <div class="detail-index-top-right">
          <div class="resources-detail-top-title">{{ info.name }}</div>

          <div class="resources-detail-top-des" v-if="info.urls.length > 0">
            <div class="resources-detail-top-des-top">
              <div class="resources-detail-top-des-top-icon">
                <img
                  style="width: 100%; height: 100%"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_title_left_icon.png"
                  alt=""
                />
              </div>
              <div class="resources-detail-top-des-top-title">集数选择</div>
            </div>
            <div class="resources-detail-top-des-top-ul">
              <div v-for="(chapter, index) in chapterList" :key="index">
                <div
                  class="resources-detail-top-des-status"
                  :class="showChapterId == chapter.id ? 'selected-status' : ''"
                >
                  <a
                    href="javascript:void(0)"
                    @click="playChapter(chapter.id)"
                    >{{ index + 1 }}</a
                  >
                </div>
              </div>
            </div>
          </div>



          <!-- <div class="resources-detail-top-des" v-if="info.urls.length > 0">
            <div class="resources-detail-top-des-top">
              <div class="resources-detail-top-des-top-icon">
                <img
                  style="width: 100%; height: 100%"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_title_left_icon.png"
                  alt=""
                />
              </div>
              <div class="resources-detail-top-des-top-title">集数下载</div>
            </div>
            <div class="resources-detail-top-des-top-ul">
              <div v-for="(chapter, index) in chapterList" :key="index">
                <div
                  class="resources-detail-top-des-status"
                  :class="showChapterId2 == chapter.id ? 'selected-status' : ''"
                >
                  <a
                    href="javascript:void(0)"
                    @click="downChapter(chapter.url,chapter.id)"
                    >{{ index + 1 }}</a
                  >
                </div>
              </div>
            </div>
          </div> -->

        </div>
      </div>
      <div class="detail-index-bottom">
        <div class="detail-index-bottom-content">
          <div class="detail-index-top-content-footerPic">
            <img
              style="width: 100%; height: 100%"
              src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
              alt=""
            />
          </div>
          <div class="detail-index-bottom-content-top">
            <div class="detail-index-bottom-content-top-title">资源详情</div>
          </div>

          <div class="detail-index-bottom-content-bottom">
            <div class="detail-index-bottom-content-bottom-box">
              <div class="detail-index-bottom-content-bottom-box-top">
                <div class="detail-index-bottom-content-bottom-box-top-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_title_left_icon.png"
                    alt=""
                  />
                </div>
                <div class="detail-index-bottom-content-bottom-box-top-title">
                  {{ info.name }}
                </div>
              </div>
              <div class="detail-index-bottom-content-bottom-box-bottom">
                <div v-html="info.des">{{ info.des }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cdetail from "../components/Cdetail";
import {
  queryResourceList,
  queryResourceLike,
  queryResourceShare,
  queryResourceUp,
} from "@/api/common";
import { queryResourceDetail } from "@/api/resource";
import { videoPlayer } from "vue-video-player";
import axios from "axios";
export default {
  components: { Cdetail, videoPlayer },
  data() {
    return {
      shareCount: 0,
      upCount: 0,
      likeCount: 0,
      playerOptions: {
        // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",

        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{}],
        poster: "../../static/images/test.jpg", //你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: true,
          fullscreenToggle: true, //全屏按钮
        },
        width: "612px",
        height: "360px",
      },
      showChapterId: null,
      detailType: "resources",
      info: {
        urls: [],
      },
      resources_team_list: [],
      chapterList: [],
      id: null,
      // 是否收藏
      isShouCang: false,
      // 是否点赞
      isLike: false,
      resource_type: 1,
      currentUrl:'',
      currentType:0,  //0音频 1视频
      playStatus:true,
      showChapterId2:null,
      name:this.$route.query.name
    };
  },
  created() {
    let id = this.$route.query.id;
    if (!id) {
      this.$router.push({ path: "/" });
    }
    this.id = id;
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    this.getResourceInfo();
    /*  axios.all([queryResourceLike(),queryResourceShare(),queryResourceUp()]).then(axios.spread(function(like,share,up){
                //当这两个请求都完成的时候会触发这个函数，两个参数分别代表返回的结果
                //console.info(like)
            }));*/

    // console.log(queryResourceLike())
  },
  updated() {
    if (window.localStorage.getItem("shoucang")) {
      const obj1 = JSON.parse(window.localStorage.getItem("shoucang"));
      // console.log(obj1);
      this.isShouCang = obj1.isShouCang;
      this.likeCount = obj1.likeCount;
    }
    if (window.localStorage.getItem("liking")) {
      const obj2 = JSON.parse(window.localStorage.getItem("liking"));
      // console.log(obj2);
      this.isLike = obj2.isLike;
      this.upCount = obj2.upCount;
    }
  },
  watch: {
    $route: function(newVal) {
      // console.log(newVal);
      this.id = newVal.query.id;
      this.getResourceInfo();
    },
  },
  methods: {
    onPlayerPause(e) {
      console.log(e)
      this.playStatus = false
    },
    onPlayerPlay(e) {
      console.log(e)
      this.playStatus = true
    },
    // 收藏
    shouCang() {
      this.isShouCang = !this.isShouCang;
      if (this.isShouCang) {
        this.likeCount += 1;
      } else {
        this.likeCount -= 1;
      }
      window.localStorage.setItem(
        "shoucang",
        JSON.stringify({
          isShouCang: this.isShouCang,
          likeCount: this.likeCount,
        })
      );
    },
    // 点赞
    Liking() {
      this.isLike = !this.isLike;
      if (this.isLike) {
        this.upCount += 1;
      } else {
        this.upCount -= 1;
      }
      window.localStorage.setItem(
        "liking",
        JSON.stringify({ isLike: this.isLike, upCount: this.upCount })
      );
    },
    changeInfo(id) {
      // console.log(1);
      //   this.$router.replace( { id: id } );
      this.$router.push(`edetail?id=${id}`);
      document.documentElement.scrollTop = 0;
      //   this.id = id;
    },
    downChapter(url,id) {
      this.showChapterId2 = id;
      // var url = "http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4"
      let name = 'a.mp4'
      let xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.responseType = 'blob' // 返回类型blob
      // 监听进度
      xhr.onprogress = function (e) {
        console.log(e)
        if (e.lengthComputable) {
          // 文件总体积
          console.log(e.total)
          // 已下载体积
          console.log(e.loaded)
          setTimeout(res=>{
            if(e.total < 50) {
              alert('文件异常，无法下载')
            }
          },1000)
        }
      }
      xhr.onload = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          alert('视频正在下载,请耐心等待')
          let blob = this.response
          // 转换一个blob链接
          let u = window.URL.createObjectURL(new Blob([blob]))
          let a = document.createElement('a')
          a.download = name
          a.href = u
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          a.remove()
          // 释放
          window.URL.revokeObjectURL(u)
        }
      }
      xhr.onerror = function () {
        alert('此视频因版权问题不支持下载')
      }
      xhr.send()
    },
    playChapter(id) {
      console.log('进入')
      this.showChapterId = id;
      let videoUrl = "";
      console.log(this.chapterList)
      for (let i = 0; i < this.chapterList.length; i++) {
        if (this.chapterList[i].id == this.showChapterId) {
          videoUrl = this.chapterList[i].url;
          console.log(videoUrl)
          this.currentUrl = videoUrl
          this.playerOptions.sources.unshift({
            type: "video/mp4", //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: videoUrl, //url地址
          });
        }
      }
      var yinpin = ['MP3','AAC','WAV','WMA','CDA','FLAC','M4A','MID','MKA','MP2','MPA','MPC','APE','OFR','OGG','RA','WV','TTA','AC3','DTS']
      var shipin = ['AVI','ASF','WMV','AVS','FLV','MKV','MOV','3GP','MP4','MPG','MPEG','DAT','OGM','VOB','RM','RMVB','TS','TP','IFO','NSV']
      var currUrl = ''
      if(this.currentUrl!='') {
        
        var currUrl = this.currentUrl.split('.')[(this.currentUrl.split('.').length)-1]
        
        currUrl  = currUrl.toUpperCase()
        if(yinpin.indexOf(currUrl)!=-1) {
          this.currentType = 0
          this.playerOptions.controlBar.fullscreenToggle = false
        }else if(shipin.indexOf(currUrl)!=-1) {
          this.currentType = 1
          this.playerOptions.controlBar.fullscreenToggle = true
        }
        
      }
      
      console.log(this.currentType)

      //  console.info(videoUrl)
    },
    //
    getRecommendList() {
      let self = this;
      queryResourceList({
        aid: process.env.VUE_APP_AID,
        category_id: this.info.category_id,
      }).then((res) => {
        let rows = [];
        for (let i = 0; i < res.data.datalist.length; i++) {
          if (res.data.datalist[i].id == self.id) {
            continue;
          }
          if (rows.length >= 3) {
            break;
          }
          rows.push(res.data.datalist[i]);
        }
        this.resources_team_list = rows;
      });
    },
    getResourceInfo() {
      let self = this;
      queryResourceDetail({ id: this.id }).then((res) => {
        const regex = new RegExp("<img", "gi");
        res.data.des = res.data.des.replace(
          regex,
          `<img style="max-width: 100%; height: auto;margin:0 auto"`
        );
        // console.log(res);
        this.resource_type = res.data.resource_type;
        this.info = res.data;
        this.chapterList = this.info.urls;
        this.getRecommendList();

        let params = {
          aid: process.env.VUE_APP_AID,
          resource_type: this.info.resource_type,
          resource_id: this.id,
        };
        //queryResourceLike(params),queryResourceShare(params),queryResourceUp(params)
        axios
          .all([
            queryResourceLike(params),
            queryResourceShare(params),
            queryResourceUp(params),
          ])
          .then(
            axios.spread(function(a, b, c) {
              self.likeCount = a.data;
              self.shareCount = b.data;
              self.upCount = c.data;
            })
          );
      });
    },
    getChineseNumber(num) {
      if (!/^\d*(\.\d*)?$/.test(num)) {
        alert("Number is wrong!");
        return "Number is wrong!";
      }
      var AA = new Array(
        "零",
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九"
      );
      var BB = new Array("", "十", "百", "千", "万", "亿", "点", "");
      var a = ("" + num).replace(/(^0*)/g, "").split("."),
        k = 0,
        re = "";
      for (var i = a[0].length - 1; i >= 0; i--) {
        switch (k) {
          case 0:
            re = BB[7] + re;
            break;
          case 4:
            if (
              !new RegExp("0{4}\\d{" + (a[0].length - i - 1) + "}$").test(a[0])
            )
              re = BB[4] + re;
            break;
          case 8:
            re = BB[5] + re;
            BB[7] = BB[5];
            k = 0;
            break;
        }
        if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0)
          re = AA[0] + re;
        if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re;
        k++;
      }
      if (a.length > 1) {
        //加上小数部分(如果有小数部分)
        re += BB[6];
        for (var i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)];
      }
      return re;
    },
    goBack() {
      let routeUrl = this.$router.resolve({
        path: `/resource`,
        query: { id:this.$route.query.pid,cat_id:this.$route.query.cat_id,navId:this.$route.query.navId,name:this.$route.query.name},
      });
      window.location = routeUrl.href
    }
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/detail.scss";
@import "../../assets/scss/resources_edetail.scss";
.selected-status a {
  color: white;
}
.video-js{
  height: 100%;
}
.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar{
  position: absolute;
  z-index: 2;
}
.vis-custom-skin > .video-js .vjs-control-bar{
  position: absolute;
  z-index: 2;
}
.vjs-has-started .vjs-control-bar{
  position: absolute;
  z-index: 2;
}
// .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
//     visibility: visible;
//     opacity: 1;
//     pointer-events: all;
//     transition: visibility 1s, opacity 1s;
//     position: absolute;
//     z-index: 2;
// }
// .vis-custom-skin > .video-js .vjs-control-bar{
//     visibility: visible;
//     opacity: 1;
//     pointer-events: all;
//     transition: visibility 1s, opacity 1s;
//     position: absolute;
//     z-index: 2;
// }
// .vjs-has-started .vjs-control-bar {
//         visibility: visible;
//     opacity: 1;
//     pointer-events: all;
//     transition: visibility 1s, opacity 1s;
//     position: absolute;
//     z-index: 2;
// }
.yinpinImg{
  width: 5.5rem;
  height: 3.4rem;
  position: absolute;
  z-index: 1;
  top: 10px;
  bottom: 0px;
  margin: auto;
}
.yinpinImg2{
    width: 2rem;
    height: 2rem;
    position: absolute;
    z-index: 1;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    margin: auto;
}
.detail-index-top-left{
  position: relative;
}
.yinpinImg2x{
      z-index: 1;
      background-size: contain;
      -webkit-animation: round_animate 8s linear infinite;
      animation: round_animate 8s linear infinite;
    }
    @keyframes round_animate {
      to {
        transform: rotate(1turn);
      }
}
.yinpinImg3{
    position: absolute;
    z-index: 1;
    width: 132px;
    height: 74px;
    left: 92px;
    top: 27px;
}
</style>
<style lang="scss" scoped>
.resources-detail-top-des{
height: auto;
margin-bottom: 0.32rem;
}
</style>
